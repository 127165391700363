import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataService } from "./data.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  refresh = true;

  API = environment.API;

  constructor(
    private router: Router,
    private http: HttpClient,
    private dataService: DataService
  ) {}

  login(datas) {
    const credentials = {
      password: datas.password,
      username: datas.login,
    };
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    this.http
      .post(this.API + "/token/", JSON.stringify(credentials), { headers })
      .subscribe(
        (result: any) => {
          if (datas.remember) {
            try {
              localStorage.setItem("refreshToken", result.refresh);
            } catch (err) {
              console.log(err);
              this.dataService.showNotification(
                "Critical error: " + err,
                null,
                4000,
                "error"
              );
            }
          } else {
            try {
              localStorage.removeItem("refreshToken");
            } catch (err) {
              console.log(err);
              this.dataService.showNotification(
                "Critical error: " + err,
                null,
                4000,
                "error"
              );
            }
          }
          try {
            sessionStorage.setItem("refreshToken", result.refresh);
            sessionStorage.setItem("accessToken", result.access);
          } catch (err) {
            console.log(err);
            this.dataService.showNotification(
              "Critical error: " + err,
              null,
              4000,
              "error"
            );
          }
          this.dataService.showNotification(
            "You have been successfully logged in.",
            null,
            4000,
            "success"
          );
          this.router.navigateByUrl("/prayers");
        },
        (err: HttpErrorResponse) => {}
      );
  }

  refreshToken() {
    const data = {
      refresh: sessionStorage.getItem("refreshToken"),
    };
    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.http.post(this.API + "/refresh-token/", JSON.stringify(data), {
      headers,
    });
  }

  isAuthenticated() {
    const promise = new Promise((resolve, reject) => {
      let refreshToken = sessionStorage.getItem("refreshToken");
      if (!refreshToken) {
        try {
          refreshToken = localStorage.getItem("refreshToken");
          sessionStorage.setItem("refreshToken", refreshToken);
        } catch (err) {
          this.dataService.showNotification(
            "Critical error: " + err,
            null,
            4000,
            "error"
          );
        }
      }
      if (!refreshToken) {
        this.dataService.logOut(
          "You have to be logged in to have access to this page. Please input your credentials."
        );
        resolve(false);
      } else {
        const data = {
          refresh: refreshToken,
        };
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        this.http
          .post(this.API + "/refresh-token/", JSON.stringify(data), { headers })
          .subscribe(
            (result: any) => {
              try {
                const newAccessToken = result.access;
                const newRefreshToken = result.refresh;
                sessionStorage.setItem("refreshToken", newRefreshToken);
                sessionStorage.setItem("accessToken", newAccessToken);
                localStorage.getItem("refreshToken")
                  ? localStorage.setItem("refreshToken", newRefreshToken)
                  : null;
                resolve(true);
              } catch (err) {
                this.dataService.showNotification(
                  "Critical error: " + err,
                  null,
                  4000,
                  "error"
                );
                this.dataService.logOut(
                  "You have to be logged in to have access to this page. Please input your credentials."
                );
                resolve(false);
              }
            },
            (err: HttpErrorResponse) => {
              resolve(false);
            }
          );
      }
    });
    return promise;
  }
}
